import io from 'socket.io-client'
export const socket = io(process.env.REACT_APP_BACK_END_URL, {
    withCredentials: true,
    autoConnect: false
})

export const connect = () => {
    socket.connect()
    
}
export const disconnect = () => {
    socket.disconnect()
}