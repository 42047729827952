import { useState } from "react";
import { useUserContext } from "./useUserContext";

export const useUpdate = () => {
    const [error,setError] = useState(null)
    const [isLoading,setIsLoading] = useState('')
    const {dispatch} = useUserContext()
    const update = async (data) =>{
        if(data){
            await dispatch({type:'RESTORE',payload:data})
            return
        }
        setIsLoading(true)
        setError(null)
        let success = await fetch('/api/UserData/check', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            cache: "no-cache"
          }).then(
            async (res) => { 
                if(res.ok){
                    const data = await res.json()
                    await dispatch({type:'RESTORE',payload:data})
                    setIsLoading(false)
                    return true
                }
                else{
                    setIsLoading(false)
                    const error = await res.json()
                    return false
                }
            }
        ).catch(error => {
            setIsLoading(false)
            setError(error)
            return false
        })
        return success
    }
    return {update, isLoading, error}
}