import { useState } from "react";
import { useUserContext } from "./useUserContext";
import { connect } from "../utils/SocketIoUtils";
export const useLogin = () => {
    const [error,setError] = useState(null)
    const [isLoading,setIsLoading] = useState('')
    const {dispatch} = useUserContext()
    const login = async (email,password) =>{
        setIsLoading(true)
        setError(null)
        let success = await fetch('/api/UserData/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email , password }),
            credentials: 'include',
            cache: "no-cache"
          }).then(
            async (res) => { 
                if(res.ok){
                    connect()
                    const data = await res.json()
                    await dispatch({type:'LOGIN',payload:data})
                    setIsLoading(false)
                    return {ok:true}
                }
                else{
                    setIsLoading(false)
                    const error = await res.json()
                    console.log(error);
                    return {ok: false,message:error.message}
                }
            }
        ).catch(error => {
            setIsLoading(false)
            setError(error)
            return { ok: false, message:"Server Error"}
        })
        return success
    }
    return {login, isLoading, error}
}