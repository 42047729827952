import { useUserContext } from "./useUserContext"
import { disconnect } from "../utils/SocketIoUtils"
export const useLogout = () => {
    const { dispatch } = useUserContext()
    const logout = async () => {
        disconnect()
        let success = fetch('/api/UserData/logout', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include'
          }).then(async (res) => {
            if(res.ok){
                await dispatch({ type: 'LOGOUT' })
                return true
            }
            else {
                return false
            }
        }).catch((error)=>{
            return false
        })
        return success
    }
    return { logout }
}