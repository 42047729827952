import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Login from "./pages/Login";
import AuthGuard from "./utils/AuthGuard";
import { useEffect, Suspense } from "react";
import Cookies from 'js-cookie';
import { useUserContext } from "./hooks/useUserContext";
import { useLogout } from "./hooks/useLogout";
import { swalAlert } from "./utils/SharedComponents";
import { useUpdate } from "./hooks/useUpdate";
import { socket } from "./utils/SocketIoUtils";
import { ALL, GUEST_ACCESS } from "./utils/Constants";
const JobApplicationForm = React.lazy(() => import("./components/jobs/JobApplication"));
const JobDescription = React.lazy(() => import("./components/jobs/JobDescription"));
const JobsList = React.lazy(() => import("./components/jobs/JobsList"));
const MainLayout = React.lazy(() => import("./layouts/MainLayout"));
const ChangeResetPassword = React.lazy(() => import("./pages/ChangeResetPassword"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));

function App() {
  const { dispatch, user } = useUserContext()
  const { logout } = useLogout()
  const { update } = useUpdate()
  useEffect(() => {
    const checkCookieExpiration = async () => {
      const cookie = Cookies.get('refresh');
      if (user && user.isAuth && !cookie) {
        await dispatch({ type: 'LOGOUT' })
        await logout()
        swalAlert('info', 'Logged out', 'Session Expired');
      }
      if (user && !user.isAuth && cookie && cookie == 'loggedIn') {
        const updated = await update()
        if(!updated) Cookies.remove('refresh')
      }
      if (user && user.isAuth && cookie && cookie !== 'loggedIn') {
        await dispatch({ type: 'LOGOUT' })
        await logout()
        swalAlert('info', 'Logged out', 'Session Expired');
      }

    };


    const interval = setInterval(checkCookieExpiration, 1000);
    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    socket.on('connect', () => { socket.emit('join-room', null) })
    const updateUser = async () => {
      await update()
    }
    socket.on('update-notifications', updateUser)
    socket.on('update-requests', updateUser)
    return () => {
      socket.off('connect')
      socket.off('update-notifications', updateUser)
      socket.off('update-requests', updateUser)
    }
  }, [socket, user])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <div className="App">
          <Switch>
          <Route exact path="/all-jobs/:jobId/apply">
              <AuthGuard isLazy={true} component={JobApplicationForm} Auth={[GUEST_ACCESS]} />
            </Route>
            <Route exact path="/all-jobs">
              <AuthGuard isLazy={true} component={JobsList} Auth={[GUEST_ACCESS]} />
            </Route>
            <Route exact path="/all-jobs/:jobId">
              <AuthGuard isLazy={true} component={JobDescription} Auth={[GUEST_ACCESS]} />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home">
              <AuthGuard isLazy={true} isRoute={true} component={MainLayout} Auth={ALL} />
            </Route>
            <Route exact path="/login">
              <AuthGuard isRoute={true} component={Login} Auth={[GUEST_ACCESS]} />
            </Route>
            <Route exact path="/reset-password/:token">
              <Suspense>
                <ChangeResetPassword type="reset" />
              </Suspense>
            </Route>
            <Route path="*">
                <Suspense><PageNotFound /></Suspense>
              </Route>
          </Switch>
        </div>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
