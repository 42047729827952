import { createContext, useReducer, useEffect, useState } from "react";
import { connect } from "../utils/SocketIoUtils";
import { GUEST_ACCESS } from "../utils/Constants";

export const UserContext = createContext()

export const userReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { user: action.payload }
        case 'RESTORE':
            return { user: action.payload }
        case 'LOGOUT':
            return { user: { name: "", isAuth: false, role: "", _id: "", access:GUEST_ACCESS } }
        default:
            return state
    }
}

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, { user: null })
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!state.user) {
            setLoading(true)
            fetch('/api/UserData/check', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                cache: "no-cache"
            }).then(async (res) => {
                if (res.ok) {
                    connect()
                    const data = await res.json()
                    await dispatch({ type: 'RESTORE', payload: data })
                    setLoading(false)
                }
                else {
                    await dispatch({ type: 'RESTORE', payload: { name: "", isAuth: false, role: "", _id: "" , access: GUEST_ACCESS} })
                    setLoading(false)
                }
            }).catch(async (error) => {
                await dispatch({ type: 'RESTORE', payload: { name: "", isAuth: false, role: "", _id: "", access: GUEST_ACCESS } })
                setLoading(false)
            })
        }
    }, [])
    return (
        <UserContext.Provider value={{ ...state, dispatch, loading }}>
            {children}
        </UserContext.Provider>
    )
}
