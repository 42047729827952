import "../css/login.css";
import loginImg from "../assests/login.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import swal2 from 'sweetalert2';
import { useLogin } from "../hooks/useLogin";
import Alert from '@mui/material/Alert';
import { Button,Box, FormControl, TextField, Link } from "@mui/material";

const NormalLoginForm = () => {
    const { login } = useLogin()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const [forgetPassword, setForgetPassword] = useState(true);
    const [waiting,setWaiting] = useState(false)
    const [error,setError] = useState(null)
    const handleIsForget = (e) =>{
      setForgetPassword(!forgetPassword);
      setError(null)
    };

    const handleSubmit = async (e) => {
      e.preventDefault()
      setWaiting(true)
      if(forgetPassword)
      {
        login(email,password).then((res)=>{
          if(res.ok){
            setError(null)
            setWaiting(false)
            history.push('/home')
          }
          else{
            setError(res)
            setWaiting(false)
          }

        }).catch((error)=>{
          console.log(error);
          setWaiting(false)
          swal2.fire("Failure!",'Server error cannot process requests currently', "error");
        })
      }
      else{
        fetch('/api/UserData/forget-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
          credentials: "include"
        })
        .then(result => {
          
          if(result.ok)
          {
            swal2.fire("Done!", "an email with reset password was sent, please check your mail", "success");
            setWaiting(false)
          } else {
            const getMessage = async()=>{
              const data = await result.json()
              swal2.fire("Failed!", data.message?  data.message:"There seems to be a problem, email not sent", "error");
            }
            getMessage()
            setWaiting(false)
          }
        }).catch((error)=>{
          console.log(error);
          setWaiting(false)
          swal2.fire("Failure!",'Server error cannot process requests currently', "error");
        });
      }
      
    };
    
    return (
      <div className="mainContainer">
        <div className="hidden">Successfully logged in...</div>
        <div className="lContainer">
          <div className="lItem">
            <div className="loginImage">
              <img
                src={loginImg}
                width="300"
                style={{ position: "relative" }}
                alt="login"
              />
            </div>
            <div className="loginForm">
              <h2>{forgetPassword? 'Login':'Forget Password'}</h2>
              <Box component="form" onSubmit={handleSubmit} className="login-form">
                <FormControl fullWidth required>
                    <TextField
                    value={email}
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    fullWidth
                    size="small"
                    margin='dense'
                    />
                </FormControl>
                {forgetPassword && <FormControl fullWidth required>
                  {
                    <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    required
                    fullWidth
                    size="small"
                    margin='dense'
                    />
                  }
                </FormControl>}
                  <div style={{display:'flex',flexFlow:'row', margin:'5px 0'}} >
                    <Link style={{marginLeft:'auto'}} type='button' component="button" onClick={handleIsForget} >{forgetPassword? 'forget password':'back to login'}</Link>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className="login-form-button"
                    disabled={waiting}
                    sx={{marginBottom:'20px'}}
                  >
                    {forgetPassword? 'Log in':"Send Email"}
                  </Button>
                <FormControl fullWidth margin='normal' sx={error? {}:{display:'none'}}>
                  <Alert severity="error">{`${error? error.message:'Server error'}`}</Alert>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default NormalLoginForm;