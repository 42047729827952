import swal2 from 'sweetalert2';

export const swalAlert = async (icon, title, text) => {
    swal2.fire({
        icon: icon,
        title: title,
        text:text,
        willOpen: () => {
            const swalModal = document.querySelector('.swal2-container');
            if (swalModal) {
                swalModal.style.zIndex = 1500;
            }
        },
       });
};



